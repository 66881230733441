<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular :size="60" color="primary" indeterminate />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    serviceType: vm.$route.params.service,
    serviceId: vm.$route.params.serviceId,
    professionalId: vm.$route.params.professionalId,
  }),
  mounted() {
    const url = '/hiring/' + this.professionalId + '/' + this.serviceType + '/' + this.serviceId;
    this.$router.push(url);
  },
};
</script>
